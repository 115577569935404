var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "showEmailPreview",
        size: "lg",
        scrollable: "",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "mailbox" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Preview Email")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("Chiudi")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-card",
        {
          staticClass: "mb-2",
          attrs: { header: "Email", "header-tag": "header" },
        },
        [
          _c("b-card-text", [
            _c("p", { staticClass: "subject" }, [_vm._v(_vm._s(_vm.subject))]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.email) } }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }