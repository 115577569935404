<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Identificativi" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-input
                      name="title"
                      vid="title"
                      label="Titolo"
                      v-model="form[rep].title"
                      placeholder="Inserisci un titolo"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-select
                      name="type"
                      label="Tipo"
                      v-model="form[rep].type"
                      :options="types"
                      :taggable="false"
                      :multiple="false"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-select
                      name="type"
                      label="Seleziona Preset"
                      v-model="form.preset"
                      :options="presets"
                      @input="onSelectPreset"
                      :taggable="false"
                      :multiple="false"
                    />
                  </div>
                  <div class="form-group col-md-3 mt-3 py-2">
                    <b-button
                      type="button"
                      variant="lisaweb"
                      @click="onLoadPreset"
                      size="sm"
                    >
                      Carica Preset
                    </b-button>
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-12">
                    <base-input
                      name="subject"
                      vid="subject"
                      label="Oggetto Email"
                      v-model="form[rep].subject"
                      maxlength="500"
                      placeholder="Inserisci oggetto email"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="col-md-12">
                    <label>Testo Email:</label>
                    <base-editor
                      :height="500"
                      :image_list="images"
                      v-model="form[rep].email"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button
                type="button"
                variant="lisaweb"
                size="sm"
                @click="showEmailPreview"
              >
                Preview
              </b-button>
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
        <email-preview-modal
          :subject="form[rep].subject"
          :email="form[rep].email"
        >
        </email-preview-modal>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseEditor from "@/components/form/BaseEditor";
import EmailPreviewModal from "@/components/modals/emailPreviewModal";

export default {
  mixins: [ShortcutMixin, LisaComunicaMixin],
  data() {
    return {
      isLoading: false,
      repository: "email",
      id: this.$route.params.id,
      exceptions: ["title", "type"],
      types: [
        { value: "AVV_SCAD", text: "Avvisi scadenza" },
        { value: "MKTG", text: "Marketing" },
        { value: "SOLL", text: "Solleciti" },
        { value: "REN_PRO", text: "Rendiconti" },
      ],
      images: [],
      presets: [],
      form: {
        preset: null,
        email: {
          title: null,
          type: null,
          subject: null,
          email: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseEditor,
    EmailPreviewModal,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].text = this.form[this.rep].email;
      this.store()
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Template Email Creato`,
          });
          this.shortcut("lisacomunica_template", null, "#Email", "filterEmail");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    setPresetOptions(presets) {
      this.presets = presets.map((preset) => {
        return {
          value: String(preset.id),
          text: preset.title,
          type: preset.type,
          email: preset.text,
          subject: preset.subject,
        };
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.preset_all()
      .then((response) => {
        this.setPresetOptions(response.data.content);
        this.index("", "image")
          .then((response) => {
            this.setImages(response.data.content);
            this.isLoading = false;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
